var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('titulo-seccion',[_c('template',{slot:"area-rutas"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Inicio")]),_vm._v(" / "),_c('router-link',{attrs:{"to":"/noticias"}},[_vm._v("Noticias")]),_vm._v(" / "),_c('span',{staticClass:"active"},[_vm._v("Noticia")])],1),_c('template',{slot:"area-titulo"},[_c('h4',{staticClass:"titulo-noticia coultar-titulo"},[_vm._v(_vm._s(_vm.item.titulo))])])],2),_c('div',{staticClass:"container",attrs:{"id":"content-page"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[(_vm.item.imagen)?_c('div',{staticClass:"img-portada lg-round"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.item.imagen),expression:"item.imagen"}],staticClass:"img-fluid img-responsive",attrs:{"alt":_vm.item.titulo,"title":_vm.item.titulo}})]):_vm._e(),_c('div',{staticClass:"mt-4"},[_c('h3',{staticClass:"titulo text-secondary",style:({
              '--color-secondary': _vm.config.disenio.datos.color_secundario,
            })},[_vm._v(" "+_vm._s(_vm.item.titulo)+" ")]),_c('small',{staticClass:"mt-3 date"},[_c('i',{staticClass:"icon"},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M7 11H9V13H7V11ZM21 6V20C21 21.1 20.1 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20L3.01 6C3.01 4.9 3.89 4 5 4H6V2H8V4H16V2H18V4H19C20.1 4 21 4.9 21 6ZM5 8H19V6H5V8ZM19 20V10H5V20H19ZM15 13H17V11H15V13ZM11 13H13V11H11V13Z","fill":"#666666"}})])]),_vm._v(" "+_vm._s(_vm.item.publicado))]),(_vm.lectura)?_c('h1',{staticClass:"mb-2 text-end"},[_c('button',{staticClass:"btn btn-sm btn-fiscalia me-1",style:({
                '--background-color-btn':
                  _vm.config.disenio.datos.color_secundario,
              }),attrs:{"disabled":_vm.reading,"data-aos":"fade-up"},on:{"click":function($event){return _vm.leerNoticia()}}},[_c('i',[_c('svg',{staticClass:"w-5 h-5",attrs:{"width":"24","height":"24","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z","clip-rule":"evenodd"}})])])]),_c('button',{staticClass:"btn btn-sm btn-fiscalia me-1",style:({
                '--background-color-btn':
                  _vm.config.disenio.datos.color_secundario,
              }),attrs:{"disabled":!_vm.reading,"data-aos":"fade-up"},on:{"click":function($event){return _vm.pausarNoticia()}}},[_c('i',[_c('svg',{staticClass:"w-5 h-5",attrs:{"width":"24","height":"24","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z","clip-rule":"evenodd"}})])])]),_c('button',{staticClass:"btn btn-sm btn-fiscalia",style:({
                '--background-color-btn':
                  _vm.config.disenio.datos.color_secundario,
              }),attrs:{"data-aos":"fade-up"},on:{"click":function($event){return _vm.stopNoticia()}}},[_c('i',[_c('svg',{staticClass:"w-5 h-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M5 3.5h6A1.5 1.5 0 0 1 12.5 5v6a1.5 1.5 0 0 1-1.5 1.5H5A1.5 1.5 0 0 1 3.5 11V5A1.5 1.5 0 0 1 5 3.5z","clip-rule":"evenodd"}})])])])]):_vm._e(),_c('blockquote',{staticClass:"mt-3 format-content-noticia",style:({
              '--background-color-tertiary':
                _vm.config.disenio.datos.color_terciario,
            }),domProps:{"innerHTML":_vm._s(_vm.item.descripcion)}}),_c('div',{staticClass:"mt-3 format-content-noticia",style:({
              '--background-color-tertiary':
                _vm.config.disenio.datos.color_terciario,
            }),attrs:{"data-aos":"fade-up "},domProps:{"innerHTML":_vm._s(_vm.item.contenido)}}),_c('p',{staticClass:"text-end"},[_c('share-it',{attrs:{"targets":['twitter', 'facebook'],"round":"","outline":""},scopedSlots:_vm._u([{key:"twitter-label",fn:function(){return [_c('label',[_vm._v("Compartir")])]},proxy:true},{key:"facebook-label",fn:function(){return [_c('label',[_vm._v("Compartir")])]},proxy:true}])})],1)])])]),_c('div',{staticClass:"mt-5 mb-5 row",attrs:{"id":"navegacion"}},[_c('div',{staticClass:"col-md-6 col-sm-6 col-xs-6 text-end",attrs:{"data-aos":"fade-right","data-aos-offset":"200","data-aos-easing":"ease-in-sine"}},[(_vm.item_sig)?_c('div',[_c('router-link',{staticClass:"btn btn-sm btn-fiscalia-white",style:({
              '--background-color-btn': _vm.config.disenio.datos.color_primario,
            }),attrs:{"to":'/noticia/' + _vm.item_sig.url}},[_c('i',{staticClass:"icon me-3"},[_c('svg',{attrs:{"width":"19","height":"16","viewBox":"0 0 19 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0.318951 8.49124L9.50928 15.8744C9.57983 15.9307 9.6701 15.9625 9.76548 15.9632L11.9298 15.9776C12.1107 15.9788 12.1952 15.7739 12.059 15.6658L3.54008 8.82086L17.8512 8.91627C17.9588 8.91699 18.0474 8.8372 18.048 8.73895L18.057 7.39924C18.0576 7.30099 17.9701 7.22002 17.8625 7.2193L3.55384 7.12391L12.1633 0.393116C12.301 0.28462 12.2192 0.0808753 12.0382 0.0796688L9.80055 0.0647503C9.75408 0.0644406 9.70751 0.0797612 9.67308 0.108559L0.326156 7.41053C0.241048 7.47719 0.172614 7.55976 0.125475 7.65266C0.0783361 7.74557 0.0535909 7.84665 0.0529079 7.94909C0.052225 8.05153 0.0756202 8.15293 0.121516 8.24646C0.167412 8.33998 0.234739 8.42346 0.318951 8.49124Z","fill":"#4b9511"}})])]),_vm._v(" Anterior ")]),_c('p',{staticClass:"mt-3 d-none d-sm-block"},[_vm._v(_vm._s(_vm.item_sig.titulo))])],1):_vm._e()]),_c('div',{staticClass:"col-md-6 col-sm-6 col-xs-6 text-start",attrs:{"data-aos":"fade-left","data-aos-offset":"200","data-aos-easing":"ease-in-sine"}},[(_vm.item_ant)?_c('div',[_c('router-link',{staticClass:"btn btn-sm btn-fiscalia-white",style:({
              '--background-color-btn': _vm.config.disenio.datos.color_primario,
            }),attrs:{"to":'/noticia/' + _vm.item_ant.url}},[_vm._v(" Siguiente "),_c('i',{staticClass:"ms-3 icon"},[_c('svg',{attrs:{"width":"18","height":"16","viewBox":"0 0 18 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M17.7303 7.40879L8.491 0.0870833C8.42008 0.0312606 8.3296 0 8.23422 0H6.06989C5.88892 0 5.80577 0.205427 5.94272 0.312607L14.5071 7.10065H0.195645C0.0880404 7.10065 0 7.18103 0 7.27928V8.61902C0 8.71727 0.0880404 8.79766 0.195645 8.79766H14.5046L5.94028 15.5857C5.80333 15.6951 5.88648 15.8983 6.06745 15.8983H8.30514C8.35161 15.8983 8.39807 15.8827 8.43231 15.8536L17.7303 8.48951C17.815 8.42229 17.8829 8.33927 17.9294 8.24605C17.9759 8.15283 18 8.05159 18 7.94915C18 7.84671 17.9759 7.74546 17.9294 7.65225C17.8829 7.55903 17.815 7.47601 17.7303 7.40879Z","fill":"#4b9511"}})])])]),_c('p',{staticClass:"mt-3 d-none d-sm-block"},[_vm._v(_vm._s(_vm.item_ant.titulo))])],1):_vm._e()])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }